<template>
    <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            :width="isList ? '1000px' : '720px'"
            class="chat-box"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
            @close="handleClose">
        <div slot="title"></div>
        <div class="uf hi100">
            <!--            right--聊天内容-->
            <div class="uf-f1 uf uf-column uf-ac" v-loading="loading">
                <!--                头部标题-->
                <div class="uf uf-pc uf-ac p-2 pr doctor_header">
                    <div class="f18 fwb">智能诊断</div>
                    <div class="title-close" @click="handleClose">
                        <i class="el-icon-close"></i>
                    </div>
                </div>
                <div style="width: 80%;margin-top: 10px">
                    <el-card>
                        <el-row>
                            系统提示：{{moment().format('YYYY年MM月DD日 HH:mm:ss')}}
                        </el-row>
                        <el-row>
                            尊敬的客人您好，如果智能问答不能解决您的问题，建议选择专业顾问做咨询解答！或者直接扫码下方专属客服二维码及时为您提供服务，随时恭候您！
                        </el-row>
                        <el-row style="color: #F56C6C;font-weight: bold">
                            紧急咨询热线：0531-8260-9032，13181732881（微信同号）
                        </el-row>
                        <el-row style="color: #409EFF;font-weight: bold">
                            平台服务平台企业微信官方客服
                        </el-row>
                        <el-row style="color: #409EFF;font-weight: bold">
                            24小时在线欢迎扫码咨询
                        </el-row>
                        <el-row>
                            <img src="../../assets/img/gzh.jpg"/>
                        </el-row>
                    </el-card>

                </div>
                <!--                聊天内容-->
                <el-scrollbar class="uf-f1 wi100 sys-zd" ref="msgBox" id="chatScroll">
                    <div class="p-2" >
                        <div class="uf uf-ac uf-pc mb-2" v-if="topLoading">
                            <i class="el-icon-loading"></i>
                        </div>
                        <div v-for="(item,index) in messageList" :key="index">
                            <!--                    聊天时间-->
                            <template v-if="item.time ">
                                <div class="uf uf-ac uf-pc mb-2" v-if="getTimeDiff(item,index)">
                                    <div class="chat-time">{{item.time}}</div>
                                </div>
                            </template>
                            <!--                    专家聊天内容-->
                            <div class="uf uf-ac mb-2" v-if="item.role === 'doctor'">
                                <el-avatar shape="square" style="width: 45px;height: 45px;"
                                           src="https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png"></el-avatar>
                                <div class="sys-text" v-if="item.type === 'TEXT'">{{item.content}}</div>
                                <div v-else class="" style="margin-left: 12px;">
                                    <el-image
                                            style="max-width: 200px"
                                            fit="scale-down"
                                            :src="$cons.SERVER_PATH + item.content"
                                            :preview-src-list="srcList">
                                    </el-image>
                                </div>
                            </div>
                            <!--                   用户聊天内容-->
                            <div>
                                <div class="uf uf-ac uf-pe mb-2" v-if="item.role === 'user'">
                                    <div class="expert-text" v-if="item.type === 'TEXT'">{{item.content}}</div>
                                    <div v-else class="" style="margin-right: 12px;">
                                        <el-image
                                                style="max-width: 200px"
                                                fit="scale-down"
                                                :src="item.ongoing ? item.content : $cons.SERVER_PATH + item.content"
                                                :preview-src-list="srcList">
                                        </el-image>
                                    </div>
                                    <el-avatar shape="square" style="width: 45px;height: 45px;"
                                               :src="$cons.SERVER_PATH + userHead"></el-avatar>
                                </div>
                            </div>
                            <!--                    结束语-->
                            <!--              <div class="uf uf-ac uf-pc mb-2">-->
                            <!--                <div class="auxiliary-text">问诊结束，如有疑问可再次咨询！</div>-->
                            <!--              </div>-->
                        </div>

                    </div>
                </el-scrollbar>
                <!--                聊天输入-->
                <div style="height: 150px;width:100%;border-top: 1px solid #eeeded" class="uf uf-column">
                    <!--                输入框--title（图片）-->
<!--                    <div class="uf uf-ac uf-pj p-2">-->
<!--                        <div>-->
<!--                            <el-upload-->
<!--                                    class="upload-demo"-->
<!--                                    :action="$cons.API_SERVER_PATH + $api.QUALITY_DOCTOR.UPLOAD_IMAGE"-->
<!--                                    :data="uploadData"-->
<!--                                    :headers="uploadHeaders"-->
<!--                                    accept=".jpg,.jpeg,.JPG,.png,.PNG"-->
<!--                                    :on-preview="handlePreview"-->
<!--                                    :on-exceed="handleExceed"-->
<!--                                    :on-success="handleSuccess"-->
<!--                                    :show-file-list="false">-->
<!--                                <i class="el-icon-picture f20 cp"></i>-->
<!--                            </el-upload>-->
<!--                        </div>-->
<!--                    </div>-->
                    <!--                    输入框-->
                    <div class="uf-f1">
                        <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="contentText" resize="none"
                                  ref="sendMsg" @keyup.enter.native="onsubmit"></el-input>
                    </div>
                    <!--                    发送按钮-->
                    <div class="uf uf-pe pr-2 pb-2">
                        <el-button type="primary" size="small" @click="onsubmit">发送</el-button>
                    </div>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    import moment from 'moment'
    import Vue from 'vue'
    import {checkIsNull} from '../../utils'
    let Base64 = require('js-base64').Base64
    export default {
        name: 'intelligence',
        data () {
            return {
                moment,
                // webSocket-链接
                ws: null,
                dialogVisible: false,
                userName: '',
                search: '',
                //  消息类型（进行中，已完成)--默认显示进行中
                activeName: 'conduct',
                //   当前选中的消息
                isSelect: '',
                //   输入消息内容
                contentText: '',
                //   图片列表
                srcList: [],
                doctorInfo: {},
                uploadData: {
                    fromId: '',
                    toId: ''
                },
                messageList: [],
                uploadHeaders: {
                    token: Vue.cookie.get('marketToken')
                },
                //    历史消息记录分页参数
                page: 1,
                limit: 30,
                totalCount: 0,
                totalPage: 0,
                //  滑动触顶加载标记
                topLoading: false,
                loading: false,
                scrollHeight: 0,
                imageUrl: '',
                // 是否显示消息成员列表
                isList: false,
                //  消息成员列表
                //  消息列表分页参数
                pageIndex: 1,
                pageSize: 50,
                expertList: [],
                expertTotalPage: 0,
                messageTime: null
            }
        },
        mounted () {
            this.$nextTick(() => {
                // document.getElementById('messageScroll').addEventListener('scroll', this.messageListScroll, true)
            })
        },
        computed: {
            userId: {
                get () { return this.$store.state.user.id },
                set (val) { this.$store.commit('user/updateId', val) }
            },
            userHead: {
                get () { return this.$store.state.user.userHead },
                set (val) { this.$store.commit('user/updateUserHead', val) }
            }
        },
        methods: {
            //  初始化
            init (item) {
                this.dialogVisible = true
                // let dictItem = this.$deepClone(item) || {}
                // this.messageList.push({
                //     role: 'user',
                //     type: 'TEXT',
                //     content: item.dictName,
                //     headPath: '',
                //     time: moment().format('YYYY年MM月DD日 HH:mm:ss')
                // })
                // this.$nextTick(() => {
                //     this.getIntelligence(dictItem.dictName)
                // })
            },
            // 获取智能回复
            getIntelligence(item) {
                this.$http({
                    // url: '/intelligence/dbdx-qa/api/v1/search',
                    url: 'http://display.smartquality.cn/dbdx-qa/api/v1/search',
                    // url: this.$http.adornUrl('/dbdx-qa/api/v1/search'),
                    method: 'POST',
                    data: this.$http.adornData({
                        query: item
                    },false)
                }).then(({data}) => {
                    if (data && data.errno === 200) {
                        let list = data.data || []
                        this.messageList.push({
                            role: 'doctor',
                            type: 'TEXT',
                            content: list[0].text,
                            headPath: '',
                            time: moment().format('YYYY年MM月DD日 HH:mm:ss')
                        })
                        this.$nextTick(() => {
                            this.scrollBottm()
                        })
                    }
                })
            },
            // 发送消息
            onsubmit () {
                if (checkIsNull(this.contentText)) {
                    this.$message.warning('请输入内容')
                    return
                }
                this.messageList.push({
                    role: 'user',
                    type: 'TEXT',
                    content: this.contentText,
                    headPath: '',
                    time: moment().format('YYYY年MM月DD日 HH:mm:ss')
                })
                this.getIntelligence(this.contentText)
                this.$nextTick(() => {
                    this.contentText = ''
                })
            },
            // 滚动事件
            chatListScroll () {
                let scrollTop = this.$refs.msgBox.$refs.wrap.scrollTop
                let clientHeight = this.$refs.msgBox.$refs.wrap.clientHeight
                let scrollHeight = this.$refs.msgBox.$refs.wrap.scrollHeight
                // console.log(scrollTop, clientHeight, scrollHeight)
                if (scrollTop + clientHeight === scrollHeight) {
                    // console.log('触底加载')
                }
                if (scrollTop === 0) {
                    if (this.page < this.totalPage) {
                        this.scrollHeight = this.$deepClone(scrollHeight)
                        this.topLoading = true
                        this.page = this.page + 1
                        this.getChatRecord(true)
                    }
                }
            },
            // 聊天列表滚动
            messageListScroll () {
                let scrollTop = this.$refs.messageListBox.$refs.wrap.scrollTop
                let clientHeight = this.$refs.messageListBox.$refs.wrap.clientHeight
                let scrollHeight = this.$refs.messageListBox.$refs.wrap.scrollHeight
                if (scrollTop + clientHeight === scrollHeight) {
                    // console.log('触底加载')
                    if (this.pageIndex < this.expertTotalPage) {
                        this.pageIndex = this.pageIndex + 1
                        this.getMessageList(false, false, true)
                    }
                }
            },
            // 滚动条到底部
            scrollBottm () {
                let el = this.$refs['msgBox']
                this.$nextTick(() => {
                    el.wrap.scrollTop = el.wrap.scrollHeight
                })
            },
            // 计算时间差
            getTimeDiff (item, index) {
                let list = this.$deepClone(this.messageList)
                if (index === 0) {
                    return true
                }
                if (index > 0) {
                    if (moment(item.time).diff(moment(list[index - 1].time), 'minute') > 3) {
                        return true
                    } else {
                        return false
                    }
                }
            },
            //   关闭
            handleClose () {
                this.dialogVisible = false
                // this.ws.close()
                clearInterval(this.messageTime)
                this.messageTime = null
                this.$emit('closeCallBack')
            },
            //   消息类型切换
            handleClick () {
            }
        }
    }
</script>

<style scoped>
    .chat-box /deep/ .el-dialog {
        height: 700px;
    }

    .chat-box /deep/ .el-dialog__header {
        padding: 0;
    }

    .chat-box /deep/ .el-dialog__body {
        height: 100%;
        padding: 0;
    }

    .chat-box /deep/ .el-tabs__item {
        text-align: center;
        width: 100%;
    }

    .chat-box /deep/ .el-tabs__header {
        margin-bottom: 8px;
    }

    .chat-box /deep/ .el-textarea__inner {
        border: none;
    }

    .chat-box /deep/ .el-image__preview {
        border-radius: 4px;
    }
    /*图片加载失败*/
    .chat-box /deep/ .el-image__error{
        height: 100px;
        width: 140px;
        border-radius: 4px;
    }
    .select-style {
        background-color: #fff;
    }

    .title-close {
        position: absolute;
        top: 8px;
        right: 10px;
        cursor: pointer;
        font-size: 18px;
    }

    /*    文本溢出隐藏*/
    .text-hiding {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    /*    用户聊天气泡*/
    .sys-text {
        position: relative;
        padding: 8px;
        font-size: 14px;
        background-color: #c0ccda;
        border: 1px solid #c0ccda;
        border-radius: 4px;
        max-width: 400px;
        margin-left: 12px;
        word-break: break-all;
        word-wrap: break-word;
    }

    .sys-text::before {
        content: '';
        position: absolute;
        /*width: 10px;*/
        /*height: 10px;*/
        left: -10px;
        top: 50%;
        transform: translateY(-10px);
        /*background-color: #c0ccda;*/
        /*border-radius: 40px 0 0 0;*/
        border-top: 5px solid transparent;
        border-left: 5px solid transparent;
        border-right: 5px solid #c0ccda;
        border-bottom: 5px solid transparent;
    }

    /*    专家聊天气泡*/
    .expert-text {
        position: relative;
        padding: 8px;
        font-size: 14px;
        background-color: #3db94d;
        border: 1px solid #3db94d;
        color: #fff;
        border-radius: 4px;
        max-width: 400px;
        margin-right: 12px;
        word-break: break-all;
        word-wrap: break-word;
    }

    .expert-text:after {
        content: '';
        position: absolute;
        /*width: 10px;*/
        /*height: 10px;*/
        right: -10px;
        top: 50%;
        transform: translateY(-10px);
        /*background-color: #c0ccda;*/
        /*border-radius: 40px 0 0 0;*/
        border-top: 5px solid transparent;
        border-left: 5px solid #3db94d;
        border-right: 5px solid transparent;
        border-bottom: 5px solid transparent;
    }

    /*    辅助聊天气泡*/
    .auxiliary-text {
        padding: 5px 8px;
        background-color: rgba(0, 0, 0, .7);
        color: #fff;
        border-radius: 5px;
        text-align: center;
        font-size: 12px;
    }

    /*    聊天时间*/
    .chat-time {
        padding: 2px 5px;
        /*color: #fff;*/
        background-color: #eeeded;
        border-radius: 4px;
        text-align: center;
        font-size: 12px;
    }

    /*element滚动条*/
    .chat-box /deep/ .el-scrollbar__wrap {
        overflow-x: hidden !important;
    }
    .el-select-dropdown__wrap {
        max-height: 500px !important;
        min-height: 130px !important;
    }

    .doctor_header {
        height: 60px;
        width: 100%;
        border-bottom: 1px solid #eeeded;
        color: #fff;
        background-image: url("../../assets/img/doctorBlue.jpg");
        background-size: cover;
    }

    .el-card ::v-deep .el-card_body {
        border-radius: 50px;
    }
</style>
